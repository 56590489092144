import './index.scss'
import 'font-awesome/css/font-awesome.min.css'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { BrowserRouter } from 'react-router-dom'

import Spinner from './components/spinner/spinner'
import { store } from './redux/storeConfig/store'
import registerServiceWorker from './registerServiceWorker'

const LazyApp = lazy(() => import('./app/app'))

const App = () => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Suspense fallback={<Spinner />}>
					<LazyApp />
					<ReduxToastr
						timeOut={4000}
						newestOnTop={false}
						preventDuplicates
						position="top-left"
						transitionIn="fadeIn"
						transitionOut="fadeOut"
						progressBar
						closeOnToastrClick
					/>
				</Suspense>
			</BrowserRouter>
		</Provider>
	)
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)

registerServiceWorker()
